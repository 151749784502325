import React, { useState } from 'react'
import Footer from 'src/components/Footer'
import Panel from 'src/components/Panel'
import Settings from 'src/components/Settings'
import Line from 'src/components/Line'
import { nanoid } from 'nanoid'
import { ID_LENGTH } from 'src/defaults'

import { useDispatch, useSelector } from 'react-redux'
import { selectLine, setCoordinates, setSource } from 'src/reducers/line'
import 'src/App.css'

const defaultPanelCount = 2

const App = () => {
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false)
  const [panelIDs, setPanelIDs] = useState<string[]>(
    Array.from({ length: defaultPanelCount }, () => {
      return nanoid(ID_LENGTH)
    })
  )
  const dispatch = useDispatch()
  const { source, coordinates } = useSelector(selectLine)

  const updateMousePosition = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (source) {
      const newCoordinates = { x: event.clientX, y: event.clientY }
      const start = coordinates.start ?? newCoordinates
      dispatch(setCoordinates({ start: start, end: newCoordinates }))
    }
  }

  const resetTargets = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (source !== null) {
      dispatch(setSource(null))
      dispatch(setCoordinates({ start: null, end: null }))
    }
  }

  const createPanel = () => {
    if (panelIDs.length < 4) {
      setPanelIDs([...panelIDs, nanoid(ID_LENGTH)])
    }
  }

  // const removePanel = (panelID: string) => {
  //   return () => {
  //     setPanelIDs(panelIDs.filter((id) => id !== panelID))
  //   }
  // }

  const panels = panelIDs.map((panelID, index) => {
    return <Panel panelIndex={index.toString()} key={panelID} />
  })

  const toggleSettings = () => {
    console.log('toggle settings', settingsVisible)
    setSettingsVisible(!settingsVisible)
  }

  const settingsPanel = settingsVisible ? <Settings /> : <div />

  return (
    <main
      className="contain content vertical"
      onMouseUp={resetTargets}
      onMouseMove={updateMousePosition}
    >
      <Line />
      <div className="contain">
        {panels}
        {settingsPanel}
      </div>
      <div className="lt blank space" />
      <Footer createPanel={createPanel} toggleSettings={toggleSettings} />
    </main>
  )
}

export default App
