import { createSlice } from '@reduxjs/toolkit'
import { setObject } from 'src/utils'
import { Element } from 'src/types'
import { ID_LENGTH } from 'src/defaults'

export type ElementRecord = Record<string, Element>
interface ElementState {
  value: ElementRecord
}
interface RootState {
  element: ElementState
}

export const elementSlice = createSlice({
  name: 'element',
  initialState: {
    value: {},
  },
  reducers: {
    createConnection: (state: ElementState, action) => {
      const [sourceID, targetID]: [string, string] = action.payload

      // Skip creating connection if possible
      if (
        sourceID === null ||
        targetID === null ||
        sourceID === targetID ||
        // Skip if id lengths are unexpected, such as for panel IDs or other pages
        sourceID.length < ID_LENGTH ||
        targetID.length < ID_LENGTH
      ) {
        return
      }

      const dateModified = new Date().toJSON()

      const sourceElement = state.value[sourceID]

      // Skip creating connection if already defined
      if (sourceElement.connections.includes(targetID)) {
        return
      }
      const targetElement = state.value[targetID]

      // Add IDs to both connection lists
      sourceElement.connections.push(targetID)
      targetElement.connections.push(sourceID)

      const updatedSourceElement = {
        ...sourceElement,
        dateModified: dateModified,
      }
      const updatedTargetElement = {
        ...targetElement,
        dateModified: dateModified,
      }
      state.value = {
        ...state.value,
        [sourceElement.id]: updatedSourceElement,
        [targetElement.id]: updatedTargetElement,
      }
      setObject(updatedSourceElement)
      setObject(updatedTargetElement)
    },

    updateElement: (state: ElementState, action) => {
      const elementData = action.payload
      const elementID = elementData.id

      const updatedElement = {
        ...state.value[elementID],
        ...elementData,
        dateModified: new Date().toJSON(),
      }

      state.value = {
        ...state.value,
        [elementID]: updatedElement,
      }
      setObject(updatedElement)
    },

    deleteElement: (state: ElementState, action) => {
      const element: Element = action.payload
      element.connections.forEach((id) => {
        if (state.value[id]) {
          state.value[id].connections = state.value[id].connections.filter(
            (connectionID) => connectionID !== element.id
          )
        }
      })
      delete state.value[element.id]
      localStorage.removeItem(element.id)
    },

    deleteConnection: (state: ElementState, action) => {
      const { targetID, selectionID } = action.payload
      const sourceElement = state.value[selectionID]
      const targetElement = state.value[targetID]
      const targetIndex = sourceElement.connections.indexOf(targetID)
      const sourceIndex = targetElement.connections.indexOf(selectionID)

      if (targetIndex !== -1) {
        sourceElement.connections.splice(targetIndex, 1)
      }
      if (sourceIndex !== -1) {
        targetElement.connections.splice(sourceIndex, 1)
      }

      if (targetIndex !== -1 || sourceIndex !== -1) {
        state = {
          ...state,
          [sourceElement.id]: sourceElement,
          [targetElement.id]: targetElement,
        }
      }
    },
  },
})

export const {
  createConnection,
  updateElement,
  deleteElement,
  deleteConnection,
} = elementSlice.actions
export const selectElements = (state: RootState) => {
  return state.element.value
}
export const elementReducer = elementSlice.reducer
