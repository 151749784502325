import React, { useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { Element, ElementType } from 'src/types'
import { ID_LENGTH } from 'src/defaults'
import { useDispatch, useSelector } from 'react-redux'
import { createConnection, updateElement } from 'src/reducers/element'
import { selectSource, setSource } from 'src/reducers/line'

interface ModifyProps {
  element: Element
  updateSelection: (id: string | null, targetPanelIndex: string) => void
}

export const Modify = (props: ModifyProps) => {
  const dispatch = useDispatch()
  const { element, updateSelection } = props
  const source = useSelector(selectSource)
  const [editView, setEditView] = useState<boolean>(false)

  const toggleTop = () => {
    dispatch(updateElement({ id: element.id, top: !element.top }))
  }

  const toggleGroup = () => {
    if (element.dataType === ElementType.group) {
      // Determine element type
      dispatch(updateElement({ id: element.id, dataType: ElementType.text }))
    } else {
      dispatch(updateElement({ id: element.id, dataType: ElementType.group }))
      setEditView(true)
    }
  }

  const topIcon = element.top === true ? '⬢' : '⬡'
  const dataType = element.dataType === ElementType.group ? 'Group' : 'Element'
  const firstTextLine = element.description.split('\n')[0]
  const imageElement =
    element.dataType === ElementType.link ? (
      <div className="contain vertical">
        <img className="w1" src="link" alt={firstTextLine} />
      </div>
    ) : (
      <div />
    )

  const description = (
    <TextareaAutosize
      className="lt expand aLeft"
      placeholder="Text"
      value={element.description}
      onChange={(event: React.BaseSyntheticEvent) => {
        dispatch(
          updateElement({ id: element.id, description: event.target.value })
        )
      }}
    />
  )

  const modifyElements = (
    <div>
      <div className="contain">{description}</div>
    </div>
  )

  const textType = [ElementType.text, ElementType.link].includes(
    element.dataType
  )

  const displayModifyElements = editView === true || textType

  const groupStyle = element.dataType === ElementType.group ? ' group' : ''

  const groupToggle = displayModifyElements ? (
    <div
      className={'lt btn' + groupStyle}
      title="Set data type"
      onClick={toggleGroup}
    >
      {dataType}
    </div>
  ) : (
    <div className={'lt btn' + groupStyle}>{firstTextLine}</div>
  )

  const archivedText = element.archived ? 'Archived' : 'Archive'
  const editArchiveElement = displayModifyElements ? (
    <div
      className={'lt btn' + groupStyle}
      title="Archive element"
      onClick={() => {
        dispatch(updateElement({ id: element.id, archived: !element.archived }))
      }}
    >
      {archivedText}
    </div>
  ) : (
    <div
      className={'lt btn' + groupStyle}
      title="Edit element"
      onClick={() => {
        setEditView(true)
      }}
    >
      Edit
    </div>
  )

  return (
    <div>
      <div className="contain static">
        <div
          className={'lt btn' + groupStyle}
          title="Click to attach to main view, or drag to build connections"
          onClick={toggleTop}
          onMouseDown={() => {
            dispatch(setSource(element.id))
          }}
          onMouseUp={() => {
            dispatch(createConnection([source, element.id]))
            if (source && source.length < ID_LENGTH) {
              updateSelection(element.id, source)
            }
          }}
        >
          {topIcon}
        </div>
        {groupToggle}
        <div className={'lt space' + groupStyle} />
        {editArchiveElement}
      </div>
      {imageElement}
      {displayModifyElements ? modifyElements : <div />}
    </div>
  )
}
