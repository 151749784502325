import React from 'react'
import logo from 'src/logo.svg'
import { Link } from 'react-router-dom'

const Landing = () => {
  return (
    <main className="contain content">
      <div className="contain vertical">
        <div className="lt space blank" />
        <div className="lt text blur">
          <h1>
            <img src={logo} className="logo" alt="logo" />
            Cortex
          </h1>
          <h2>Build a network of your notes, tasks, links, images</h2>
        </div>
        <div className="contain center topMargin bottomMargin">
          <Link className="lt btn accentLight w3" to="/app">
            Go
          </Link>
        </div>
        <div className="lt space blank" />
        <div className="lt text blur">
          <h1>Why?</h1>
          <p>Runs locally</p>
          <p>Export your data at any time</p>
          <p>Free to use</p>
          <p>
            All of the other benefits of a{' '}
            <a href="https://en.wikipedia.org/wiki/Zettelkasten">
              Zettelkasten
            </a>
          </p>
        </div>
        <div className="lt space blank" />
      </div>
    </main>
  )
}

export default Landing
