import { configureStore } from '@reduxjs/toolkit'
import { lineReducer, initialLine } from 'src/reducers/line'
import { elementReducer } from 'src/reducers/element'
import { getAllObjects } from 'src/utils'

const initialState = {
  line: initialLine,
  element: {
    value: getAllObjects(),
  },
}

export default configureStore({
  reducer: {
    line: lineReducer,
    element: elementReducer,
  },
  preloadedState: initialState,
})
