import { Element } from 'src/types'
import { ElementRecord } from 'src/reducers/element'
import { defaultElements } from 'src/data'

const USE_STORAGE = true

// Get an object from local storage if possible
export const getObject = (id: string) => {
  if (!USE_STORAGE) {
    return
  }
  const value = localStorage.getItem(id)
  return value && JSON.parse(value)
}

// Save an object in local storage
export const setObject = (data: Element) => {
  if (!USE_STORAGE) {
    return
  }
  localStorage.setItem(data.id, JSON.stringify(data))
}

// Generate tutorial data
const generateTutorialData = (): void => {
  console.log('generate tutorial')
  Object.values(defaultElements).forEach((element) => {
    setObject(element)
  })
}

// Get all local storage data as objects
export const getAllObjects = (): ElementRecord => {
  if (!USE_STORAGE) {
    console.log('storage disabled')
    return {}
  }
  const ignoreList = ['debug']
  // return localStorage.element ?? {}
  const storedData = Object.keys(localStorage).reduce(
    (acc: ElementRecord, key) => {
      if (ignoreList.includes(key)) {
        return acc
      }
      const value = JSON.parse(localStorage[key])
      // value.dateAdded = new Date(value.dateAdded)
      // value.dateModified = new Date(value.dateModified)
      // value.dateLastUtilized = new Date(value.dateLastUtilized)
      acc[value.id] = value
      return acc
    },
    {}
  )
  if (Object.keys(storedData).length === 0) {
    generateTutorialData()
    return getAllObjects()
  }
  console.log('return found data', Object.keys(storedData).length)
  return storedData
}

export const clearStorage = () => {
  console.log('cleared storage')
  localStorage.clear()
}

export const exportData = async (elements: Record<string, Element>) => {
  const link = document.createElement('a')
  link.setAttribute('style', 'visibility:hidden')
  link.href =
    'data:application/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(elements))
  link.download = 'cortex.json'
  console.log(elements)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const importData = async (
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const reader = new FileReader()
  reader.onload = async (e) => {
    if (e.target) {
      console.log(e.target.result)
    }
  }
  if (event.target.value) {
    console.log(event.target.value)
    // reader.readAsText(event.target.files[0])
  }
}

interface QueryObject {
  [key: string]: string
}

// Generate a new query string with an updated element for a panel
export const constructQuery = (
  query: QueryObject,
  panelIndex: string,
  elementID: string | null
) => {
  if (elementID && elementID !== panelIndex) {
    query[panelIndex] = elementID
  } else {
    delete query[panelIndex]
  }

  const queryArray = Object.entries(query).map(([key, value]) => {
    return `${key}=${value}`
  })
  const queryString: string = `?${queryArray.join('&')}`
  return queryString
}

// Convert a query string into a key/value object
export const parseQueryString = (search: string) => {
  if (search.length === 0) {
    return {}
  }
  const keyValues = search.slice(1).split('&')
  return keyValues.reduce((acc: QueryObject, value) => {
    const split = value.split('=')
    if (split.length !== 2) {
      return acc
    }
    acc[split[0]] = split[1]
    return acc
  }, {})
}

// interface OldElement {
//   title: string
//   description: string
//   type: ElementType
//   top: boolean
//   created: Date
//   modified: Date
//   accessed: Date
// }

// interface OldSchema {
//   element: Record<string, OldElement>
//   connection: Record<string, string[]>
// }

// const data20: OldSchema = require('./data20.json')

// // Maps the original Cortex data structure to the one expected by this app
// export const mapOriginalDataStructure = (inputData) => {
//   const output: Record<string, Element> = {}
//   Object.keys(inputData.element).forEach((key) => {
//     const element = inputData.element[key]
//     const dataType = ElementType[element.type]
//     output[key] = {
//       id: key,
//       title: element.title ?? '',
//       description: element.description ?? '',
//       dataType: dataType ?? ElementType.text,
//       top: element.top,
//       archived: false,
//       dateAdded: new Date(element.created).toJSON(),
//       dateModified: new Date(element.modified).toJSON(),
//       dateAccessed: new Date(element.accessed).toJSON(),
//       connections: [],
//     }
//   })

//   Object.keys(inputData.connection).forEach((key) => {
//     const values = inputData.connection[key]
//     output[key].connections = output[key].connections.concat(values)
//     values.forEach((value) => {
//       output[value].connections.push(key)
//     })
//   })

//   return output
// }
