import React, { useState } from 'react'
import { clearStorage, exportData } from 'src/utils'
import { useSelector } from 'react-redux'
import { ElementRecord, selectElements } from 'src/reducers/element'

interface SettingsProps {}

const Settings = (props: SettingsProps) => {
  // const dispatch = useDispatch()
  const elements: ElementRecord = useSelector(selectElements)

  const [deleteAllConfirm, setDeleteAllConfirm] = useState<boolean>(false)

  const runExport = () => {
    exportData(elements)
  }

  const runImport = () => {
    // importData(elements)
  }

  const deleteAll = () => {
    setDeleteAllConfirm(false)
    clearStorage()
  }

  // const login = <div className="lt btn">Login</div>
  // const signUp = <div className="lt btn">Sign Up</div>
  // const archived = <div className="lt btn">View Archived</div>
  // const deleteArchived = (
  //   <div className="lt btn" onClick={deleteAll}>
  //     Delete Archived Data
  //   </div>
  // )
  const confirmDeleteAll = (
    <div className="contain static">
      <div className="lt btn expand" onClick={deleteAll}>
        Yes
      </div>
      <div
        className="lt btn expand"
        onClick={() => {
          setDeleteAllConfirm(false)
        }}
      >
        No
      </div>
    </div>
  )
  const deleteAllText = deleteAllConfirm
    ? 'Delete All Data?'
    : 'Delete All Data'
  const deleteAllComponent = (
    <div className="contain static">
      <div
        className="lt btn expand"
        onClick={() => {
          setDeleteAllConfirm(true)
        }}
      >
        {deleteAllText}
      </div>
      {deleteAllConfirm ? confirmDeleteAll : null}
    </div>
  )

  // const interface = <div className="lt btn group static">Interface</div>

  const importFile = (
    <input
      className="lt expand"
      type="file"
      name="file"
      placeholder="Select file"
      onChange={(event) => {
        if (!!event.target.files) {
          const files = Object.values(event.target.files)
          if (files.length) {
            const file = files[0]
            console.log(file, file.name)
          }
        }
      }}
    />
  )

  const importComponent = (
    <div className="contain static">
      {importFile}
      <div className="lt btn expand" onClick={runImport}>
        Import Data
      </div>
    </div>
  )

  const elementCount = Object.keys(elements).length

  const metrics = (
    <div className="contain">
      <div className="lt btn expand">{elementCount} elements</div>
      <div className="lt btn expand">Some connections</div>
    </div>
  )

  // const layout = <div />

  return (
    <div className="contain vertical fullPanel">
      <nav className="lt primary">
        <div className="contain static">
          <div className="lt btn">Settings</div>
          <div className="lt space" />
        </div>
      </nav>
      {metrics}
      <div className="lt btn" onClick={runExport}>
        Export Data
      </div>
      {importComponent}
      <div className="lt btn static" />
      {deleteAllComponent}
    </div>
  )
}

export default Settings
