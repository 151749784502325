import { Connection, Element, ElementType } from 'src/types'
export const defaultElements: Record<string, Element> = {
  ildiFmoWaTzp: {
    id: 'ildiFmoWaTzp',
    description:
      "Creating groups\n\nGroups are created in the same way as notes, but the 'Element' button can be clicked to change it to 'Group'.\n\nGroups are similar to folders or tags. They can help organize your notes by grouping them together. Groups also collapse to take up less space to help with viewing all of your notes!\n\nClick on the connected note below to learn more about faster navigation.",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: ['pvBdrmltgbIw', 'i7yQKNDmYc5x'],
  },
  hD9A22tiMrVX: {
    id: 'hD9A22tiMrVX',
    description:
      "Filters\n\nNice! You loaded this note without having to find where it's located.\n\nFilters in the top menu can be used to limit which notes are shown by type, or sorted in different orders.\n\nLinks can be saved in notes and a button will be created to easily link out of Cortex. If a link to an image is saved as a note, the image itself will be loaded.\n\nThere's no need to define these different kinds of notes, Cortex just figures it out.\n\nThat concludes the introduction to Cortex. You can find more information on our website, or contact our support team if you need any help!",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: ['i7yQKNDmYc5x'],
  },
  o27DEAgUssIC: {
    id: 'o27DEAgUssIC',
    description:
      "Connect with this note\n\nYou've navigated to the connected note!\n\nTo return to the 'Main' view, click on the ⬢ symbol in the top menu. Try this on the other panel.\n\nOnly the welcome note is visible in the 'Main' view. If you click the ⬡ symbol on this note, it will change to ⬢ and be pinned to the 'Main' view as well. Try it out!\n\nNow click on the 'Connected' group in the other panel, and open the note about 'Creating notes'.",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
  },
  '3LmghgGsfeL3': {
    id: '3LmghgGsfeL3',
    description:
      "Welcome to Cortex\n\nThis is a text note. Information can be entered here to save for later.\n\nCortex is built around connecting things together. To see how this works click on the 'Disconnected' group in the other panel. Then click and drag a line from the ⬢ symbol on this note to the ⬡ symbol on the other note that is there.\n\nYou've created your first connection! When viewing this note you can navigate directly to the connected note by clicking on it at the bottom. Try it out!",
    top: true,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
  },
  i7yQKNDmYc5x: {
    id: 'i7yQKNDmYc5x',
    description:
      "Faster navigation\n\nTo navigate more quickly, notes can also be loaded directly in other panels. This is helpful if you want to stay in the same view on one panel, but load a related note in a different one without having to find it.\n\nTo do this, just draw a connection from a note ⬡ to the 'Main' view ⬢ to load that note in a different panel. Try to load the 'Filters' note in the other panel by drawing a connection!",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: ['ildiFmoWaTzp', 'hD9A22tiMrVX'],
  },
  pvBdrmltgbIw: {
    id: 'pvBdrmltgbIw',
    description:
      "Creating notes\n\nTo create a note, just click the 'Create' button in the top menu. Try it out in the other panel!\n\nThe new note will be connected with the note that was open in the panel. This connection can be removed by clicking the 'x' on the connection.\n\nEverything automatically saves locally to your browser. Syncing across devices will be available at some point.\n\nClick on the connected note below to learn more about groups.",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: ['ildiFmoWaTzp'],
  },
}

export const defaultConnections: Connection = {
  ildiFmoWaTzp: ['pvBdrmltgbIw', 'i7yQKNDmYc5x'],
  i7yQKNDmYc5x: ['ildiFmoWaTzp', 'hD9A22tiMrVX'],
  hD9A22tiMrVX: ['i7yQKNDmYc5x'],
  pvBdrmltgbIw: ['ildiFmoWaTzp'],
}

export const triangleElements: Record<string, Element> = {
  ildiFmoWaTzp: {
    id: 'ildiFmoWaTzp',
    description:
      "Creating groups\n\nGroups are created in the same way as notes, but the 'Element' type is changed to 'Group'.",
    top: true,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
    // connections: ['pvBdrmltgbIw', 'i7yQKNDmYc5x'],
  },
  i7yQKNDmYc5x: {
    id: 'i7yQKNDmYc5x',
    description:
      'Faster navigation\n\nTo navigate more quickly, notes can also be loaded directly in other panels.',
    top: true,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
    // connections: ['ildiFmoWaTzp', 'pvBdrmltgbIw'],
  },
  pvBdrmltgbIw: {
    id: 'pvBdrmltgbIw',
    description:
      "Creating notes\n\nTo create a note, just click the 'Create' button in the top menu.",
    top: false,
    archived: false,
    dataType: ElementType.text,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
    // connections: ['ildiFmoWaTzp', 'i7yQKNDmYc5x'],
  },
}
