import React, { useEffect, useState } from 'react'
import { Element, ElementType } from 'src/types'
import { generateElement } from 'src/defaults'
import { ConnectionList } from 'src/components/ConnectionList'
import {
  filterConnections,
  PanelFilterAll,
  PanelFilter,
  PanelSort,
  sortConnections,
} from 'src/panelUtils'

import { constructQuery, parseQueryString } from 'src/utils'

import { Modify } from 'src/components/Modify'
import { SizeMe } from 'react-sizeme'
import { ForceGraph2D } from 'react-force-graph'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectSource, setSource } from 'src/reducers/line'
import {
  ElementRecord,
  createConnection,
  deleteConnection,
  selectElements,
  updateElement,
} from 'src/reducers/element'

enum PanelMode {
  list,
  graph,
}

interface PanelSettings {
  mode: PanelMode
  filter: string
  sort: string
}

interface PanelProps {
  panelIndex: string
}

type MetaGroups = 'connected' | 'disconnected' | null

const defaultSettings = {
  mode: PanelMode.list,
  filter: PanelFilterAll,
  sort: PanelSort.modified,
}

const Panel = (props: PanelProps) => {
  const { panelIndex } = props

  const dispatch = useDispatch()
  const connectionSource = useSelector(selectSource)
  const history = useHistory()

  const location = useLocation()
  const query = parseQueryString(location.search)
  const queryItem = query[panelIndex]

  const elements: ElementRecord = useSelector(selectElements)
  const [settings, setSettings] = useState<PanelSettings>({
    ...defaultSettings,
  })
  const [selection, setSelection] = useState<string | null>(queryItem ?? null)
  const [metaGroup, setMetaGroup] = useState<MetaGroups>(null)

  useEffect(() => {
    // Set state to rerender component when navigating back and forth through the browser history
    if (queryItem !== selection) {
      setSelection(queryItem ?? null)
    }
  }, [selection, queryItem])

  const updateSelection = (id: string | null, targetPanelIndex: string) => {
    setSelection(id)
    setMetaGroup(null)
    // Construct new query string and add to history
    const newQuery = constructQuery(query, targetPanelIndex, id)
    history.push(`/app${newQuery}`)
  }

  const createElement = () => {
    const newElement: Element = generateElement()
    // Generate link to the current selection before switching focus
    dispatch(updateElement(newElement))
    if (selection) {
      dispatch(createConnection([selection, newElement.id]))
    }
    updateSelection(newElement.id, panelIndex)
  }

  const deletePanelConnection = (targetID: string) => {
    if (!selection) {
      return
    }
    dispatch(deleteConnection({ targetID: targetID, selectionID: selection }))
  }

  const selectedElement = selection ? elements[selection] : undefined

  const metaStyle = 'lt btn group expand'
  const metaSelectedStyle = 'lt btn expand accentLight'

  const metaGroups = selectedElement ? (
    <div />
  ) : (
    <div className="contain static">
      <div
        className={metaGroup === 'connected' ? metaSelectedStyle : metaStyle}
        onClick={() => {
          setMetaGroup('connected')
        }}
      >
        Connected
      </div>
      <div
        className={metaGroup === 'disconnected' ? metaSelectedStyle : metaStyle}
        onClick={() => {
          setMetaGroup('disconnected')
        }}
      >
        Disconnected
      </div>
    </div>
  )

  let connections: Element[] = []
  if (!selectedElement) {
    const elementList = Object.values(elements)
    if (metaGroup === 'disconnected') {
      connections = elementList.filter(
        (element) => element.connections.length === 0
      )
    } else if (metaGroup === 'connected') {
      connections = elementList.filter(
        (element) => element.connections.length > 0
      )
    } else {
      connections = elementList.filter((element) => element.top)
    }
  } else {
    connections = selectedElement.connections.map((id) => elements[id])
  }

  connections = filterConnections(connections, settings.filter)
  sortConnections(connections, settings.sort)

  const connectionList = (
    <ConnectionList
      elements={connections}
      panelIndex={panelIndex}
      updateSelection={updateSelection}
      deleteConnection={deletePanelConnection}
    />
  )

  // Create modify view
  const modify = selectedElement ? (
    <Modify element={selectedElement} updateSelection={updateSelection} />
  ) : (
    <div />
  )

  // const panelModeSwitch = (
  //   <div
  //     className="lt btn"
  //     // title="Switch panel view mode"
  //     // onClick={() => {
  //     //   setSettings({
  //     //     ...settings,
  //     //     mode:
  //     //       settings.mode === PanelMode.list ? PanelMode.graph : PanelMode.list,
  //     //   })
  //     // }}
  //   >
  //     {settings.mode === PanelMode.list ? 'List' : 'Graph'}
  //   </div>
  // )

  const filterSelector = (
    <select
      className="lt btn"
      title="Filter data type"
      value={settings.filter}
      onChange={(event) => {
        setSettings({
          ...settings,
          filter: event.target.value,
        })
      }}
    >
      {Object.entries(PanelFilter).map(([key, value]) => {
        return (
          <option value={key} key={key}>
            {value}
          </option>
        )
      })}
    </select>
  )

  const sortSelector = (
    <select
      className="lt btn"
      title="Sort order"
      value={settings.sort}
      onChange={(event) => {
        setSettings({
          ...settings,
          sort: event.target.value,
        })
      }}
    >
      {Object.entries(PanelSort).map(([key, value]) => {
        return (
          <option value={value} key={key}>
            {value}
          </option>
        )
      })}
    </select>
  )

  const connectionIndicator = (
    <div className="lt btn static" title="Total connections">
      {connections.length}
    </div>
  )

  const createButton = (
    <div
      className="lt btn"
      title="Create a new element"
      onClick={createElement}
    >
      Create
    </div>
  )

  const hexagonButton = (
    <div
      className="lt btn"
      title="Main view"
      onClick={() => {
        updateSelection(null, panelIndex)
      }}
      onMouseUp={() => {
        if (connectionSource) {
          updateSelection(connectionSource, panelIndex)
        }
      }}
      onMouseDown={() => {
        dispatch(setSource(panelIndex.toString()))
      }}
    >
      ⬢
    </div>
  )

  interface Link {
    source: number
    target: number
  }

  const computeGraphData = (elements: Record<string, Element>) => {
    const elementIndex: Record<string, number> = {}
    const aggregatedConnections: Record<string, string[]> = {}
    const nodes: Record<string, number | string>[] = []
    const links: Link[] = []

    let maxLength = 1

    const colors = {
      [ElementType.text]: 'white',
      [ElementType.group]: '#6700cd',
      [ElementType.image]: '#00cd67',
      [ElementType.link]: '#00cdcd',
      top: '#ff1bff',
    }

    const elementList = Object.values(elements).slice(0, 50)

    elementList.forEach((element, index) => {
      elementIndex[element.id] = index
      const textLength = element.description.length
      maxLength = textLength > maxLength ? textLength : maxLength
      nodes.push({
        id: elementIndex[element.id],
        color: element.top === true ? colors.top : colors[element.dataType],
        textLength: textLength,
      })

      element.connections.forEach((connection) => {
        const sorted = [element.id, connection].sort()
        const [source, target] = sorted
        if (aggregatedConnections[source] === undefined) {
          aggregatedConnections[source] = []
        }
        aggregatedConnections[source].push(target)
      })
    })

    Object.keys(aggregatedConnections).forEach((key) => {
      Array.from(new Set(aggregatedConnections[key]))

      aggregatedConnections[key].forEach((value) => {
        const source = elementIndex[key]
        const target = elementIndex[value]
        if (source === undefined || target === undefined) {
          return
        }
        const newLink = {
          source: source,
          target: target,
        }
        links.push(newLink)
      })
    })

    return {
      nodes: nodes,
      links: links,
      maxLength: maxLength,
    }
  }

  let panelView = <div />
  switch (settings.mode) {
    case PanelMode.list:
      panelView = (
        <div>
          {modify}
          {connectionList}
        </div>
      )
      break
    case PanelMode.graph:
      const dataset = computeGraphData(elements)
      const maxValue = 10
      const minValue = 2
      const sizeRatio = maxValue / 1000
      const computeSize = (length: number) => {
        const value = length * sizeRatio
        return value > minValue ? value : minValue
      }
      panelView = (
        <SizeMe>
          {({ size }) => (
            <ForceGraph2D
              graphData={dataset}
              width={size.width ?? 0}
              // numDimensions={3}
              // @ts-ignore
              nodeColor={(node) => node.color}
              // nodeOpacity={0.8}
              // @ts-ignore
              nodeVal={(node) => computeSize(node.textLength)}
              linkColor={() => '#0064ff'}
              linkWidth={2}
              // linkOpacity={0.5}
              warmupTicks={500}
              // cooldownTicks={20}
              // cooldownTime={5000}
            />
          )}
        </SizeMe>
      )
      break
  }

  return (
    <div className="contain vertical fullPanel">
      <nav className="lt primary">
        <div className="contain static">
          {hexagonButton}
          {createButton}
          <div className="lt space" />
          {sortSelector}
          {filterSelector}
          {/*{panelModeSwitch}*/}
          {connectionIndicator}
        </div>
      </nav>
      <div className="panel">
        {metaGroups}
        {panelView}
      </div>
    </div>
  )
}

export default React.memo(Panel)
