import React from 'react'
import logo from 'src/logo.svg'

interface FooterProps {
  createPanel: () => void
  toggleSettings: () => void
}

const Footer = (props: FooterProps) => {
  return (
    <div>
      <div className="lt blank space" />
      <footer className="lt blank">
        <div className="contain static">
          <a className="lt btn" href="/">
            <img src={logo} className="logo" alt="logo" />
            Cortex
          </a>
          <div className="lt space" />
          <div className="lt btn" onClick={props.toggleSettings}>
            Settings
          </div>
          <div className="lt btn" onClick={props.createPanel}>
            +
          </div>
        </div>
      </footer>
    </div>
  )
}

// let a = <input type="file" onChange={(event) => console.log(event)} />

export default Footer
