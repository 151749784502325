import React from 'react'
import { Element, ElementType } from 'src/types'
import { ID_LENGTH } from 'src/defaults'
import { useDispatch, useSelector } from 'react-redux'
import { selectSource, setSource } from 'src/reducers/line'
import { createConnection } from 'src/reducers/element'

interface ConnectionListProps {
  elements: Element[]
  panelIndex: string
  updateSelection: (id: string | null, targetPanelIndex: string) => void
  deleteConnection: (id: string) => void
}

// interface ConnectionDisplayProps {
//   id: string
//   icon: string
//   title: string
// }

export const ConnectionList = (props: ConnectionListProps) => {
  const dispatch = useDispatch()
  const { elements, panelIndex, updateSelection, deleteConnection } = props
  const source = useSelector(selectSource)

  const ConnectionDisplay = (element: Element) => {
    const topIcon = element.top === true ? '⬢' : '⬡'
    const text = element.description.split('\n')[0]
    const link =
      element.dataType === ElementType.link ? (
        <a className="lt btn" href="/">
          Link
        </a>
      ) : null

    const groupStyle = element.dataType === ElementType.group ? ' group' : ''

    return (
      <div className="contain static" key={element.id}>
        <div
          className={'lt btn' + groupStyle}
          onMouseDown={() => {
            dispatch(setSource(element.id))
          }}
          onMouseUp={() => {
            dispatch(createConnection([source, element.id]))
            if (source && source.length < ID_LENGTH) {
              updateSelection(element.id, source)
            }
          }}
        >
          {topIcon}
        </div>
        {link}
        <div
          className={'lt btn expand aLeft' + groupStyle}
          onClick={() => {
            updateSelection(element.id, panelIndex)
          }}
        >
          {text}
        </div>
        <div
          className={'lt btn' + groupStyle}
          onClick={() => {
            deleteConnection(element.id)
          }}
        >
          ×
        </div>
      </div>
    )
  }

  return (
    <div>
      {elements.map((element) => {
        return ConnectionDisplay(element)
      })}
    </div>
  )
}
