import { ElementType } from 'src/types'
import { nanoid } from 'nanoid'

export const ID_LENGTH = 12

export const generateElement = () => {
  return {
    id: nanoid(ID_LENGTH),
    title: '',
    description: '',
    dataType: ElementType.text,
    top: false,
    archived: false,
    dateAdded: new Date().toJSON(),
    dateModified: new Date().toJSON(),
    dateAccessed: new Date().toJSON(),
    connections: [],
  }
}
