export enum ElementType {
  text = 'text',
  image = 'image',
  link = 'link',
  group = 'group',
}

export interface Element {
  id: string
  description: string
  dataType: ElementType
  top: boolean
  archived: boolean
  dateAdded: string
  dateModified: string
  dateAccessed: string
  // utilized: number
  connections: string[]
}

export type Connection = { [key: string]: string[] }
