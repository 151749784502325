import { createSlice } from '@reduxjs/toolkit'
import { LineProps } from 'src/components/Line'

interface LineState {
  source: string | null
  target: string | null
  coordinates: LineProps
}

interface RootState {
  line: LineState
}

export const initialLine = {
  source: null,
  target: null,
  coordinates: {
    start: null,
    end: null,
  },
}

export const lineSlice = createSlice({
  name: 'line',
  initialState: initialLine,
  reducers: {
    setCoordinates: (state: LineState, action) => {
      state.coordinates = action.payload
    },
    setSource: (state: LineState, action) => {
      state.source = action.payload
    },
  },
})

export const { setCoordinates, setSource } = lineSlice.actions
export const selectSource = (state: RootState) => {
  return state.line.source
}
export const selectTarget = (state: RootState) => {
  return state.line.target
}
export const selectSourceTarget = (state: RootState) => {
  return {
    source: state.line.source,
    target: state.line.target,
  }
}
export const selectCoordinates = (state: RootState) => {
  return state.line.coordinates
}
export const selectLine = (state: RootState) => {
  return state.line
}
export const lineReducer = lineSlice.reducer
