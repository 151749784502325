import React from 'react'
import ReactDOM from 'react-dom'
import 'src/index.css'
import App from 'src/App'
import Landing from 'src/Landing'
import reportWebVitals from 'src/reportWebVitals'
import store from 'src/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/app" component={App} />
        <Route exact={true} path="/">
          <Landing />
        </Route>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
