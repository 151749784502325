import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCoordinates } from 'src/reducers/line'

interface Coordinates {
  x: number
  y: number
}

export interface LineProps {
  start: Coordinates | null
  end: Coordinates | null
}

interface LineSettings {
  color: string
  style: string
  width: number
}

const defaultSettings = {
  color: '#0064ff',
  style: 'solid',
  width: 3,
}

const Line = () => {
  const [settings] = useState<LineSettings>({
    ...defaultSettings,
  })
  const { start, end } = useSelector(selectCoordinates)

  // Skip if line can't be drawn
  if (start === null || end === null) {
    return <div />
  }

  // Calculate real start positions including scroll offset
  const initialY = start.y + window.scrollY
  const initialX = start.x + window.scrollX

  const dy = end.y - start.y
  const dx = end.x - start.x

  const angle = (Math.atan2(dy, dx) * 180) / Math.PI
  const width = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) - 5

  const style = {
    position: 'absolute',
    zIndex: 10,
    borderTopColor: settings.color,
    borderTopStyle: settings.style,
    borderTopWidth: settings.width,
    top: `${initialY}px`,
    left: `${initialX}px`,
    width: `${width}px`,
    transform: `rotate(${angle}deg)`,
    transformOrigin: '0 0',
  } as React.CSSProperties

  return <div style={style}></div>
}

export default Line
