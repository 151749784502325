import { Element, ElementType } from 'src/types'

export const PanelFilterAll = 'all'

export const PanelFilter = {
  [PanelFilterAll]: 'All',
  [ElementType.text]: 'Text',
  [ElementType.link]: 'Link',
  [ElementType.image]: 'Image',
  [ElementType.group]: 'Group',
}

export const PanelSort = {
  name: 'Name',
  links: 'Links',
  created: 'Created',
  modified: 'Modified',
  recent: 'Recent',
}

export const filterConnections = (
  connections: Element[],
  filterType: string
) => {
  connections = connections.filter((element) => !element.archived)
  if (filterType === PanelFilterAll) {
    return connections
  }
  return connections.filter((connection) => {
    return connection.dataType === filterType
  })
}

export const sortConnections = (connections: Element[], sortType: string) => {
  switch (sortType) {
    case PanelSort.name:
      connections.sort((a, b) => {
        const aValue = a.description
        const bValue = b.description
        if (aValue < bValue) {
          return -1
        }
        if (aValue > bValue) {
          return 1
        }
        return 0
      })
      break
    case PanelSort.links:
      connections.sort((a, b) => {
        return (
          new Date(b.dateModified).getTime() -
          new Date(a.dateModified).getTime()
        )
      })
      break
    case PanelSort.created:
      connections.sort((a, b) => {
        return new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime()
      })
      break
    case PanelSort.modified:
      connections.sort((a, b) => {
        return (
          new Date(b.dateModified).getTime() -
          new Date(a.dateModified).getTime()
        )
      })
      break
    case PanelSort.recent:
      connections.sort((a, b) => {
        return (
          new Date(b.dateAccessed).getTime() -
          new Date(a.dateAccessed).getTime()
        )
      })
      break
  }
}
